import React, { Dispatch, SetStateAction } from 'react'
import ReactLightbox from 'lightbox-react'
import 'lightbox-react/style.css'

export interface Image {
  url: string
  legend?: string
}

interface Props {
  index: number
  images?: Array<Image>
  setIndex: Dispatch<SetStateAction<number>>
  setImages: Dispatch<SetStateAction<Array<Image>>>
}

const Lightbox = ({ index, images, setIndex, setImages }: Props) => (
  <>
    {images && (
      <ReactLightbox
        mainSrc={images[index].url}
        onCloseRequest={() => {
          setImages(null)
          setIndex(0)
        }}
        prevSrc={images[(index + images.length - 1) % images.length].url}
        nextSrc={images[(index + 1) % images.length].url}
        onMovePrevRequest={() =>
          setIndex((index + images.length - 1) % images.length)
        }
        onMoveNextRequest={() => setIndex((index + 1) % images.length)}
        imageCaption={images[index].legend}
      />
    )}
  </>
)

export default Lightbox
