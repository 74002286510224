import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import * as st from '~/assets/styl/Map.module.styl'
import Pin from '~/assets/svg/Marcador.svg'

interface Props {
  location: {
    lat: string
    lng: string
  }
}

const Map = ({ location: { lat, lng } }: Props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: '',
  })

  return isLoaded ? (
    <GoogleMap
      zoom={13}
      center={{
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      }}
      options={{
        fullscreenControl: false,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      }}
      mapContainerClassName={st.container}
    >
      <Marker
        icon={{
          url: Pin,
          anchor: new google.maps.Point(15, 44),
          size: new google.maps.Size(30, 44),
        }}
        clickable={false}
        position={{
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        }}
      />
    </GoogleMap>
  ) : (
    <></>
  )
}

export default Map
